import { FC, MouseEventHandler, ReactNode, useEffect, useState } from 'react'
import { useToggle } from 'react-use'

import { Col, Radio, Row } from 'antd'

import Color from 'color'

import Text from '..//text/Text'
import { useColor } from '../app'
import IvyIcon from '../icon'
import { Avatar } from '../image/Avatar'
import { ListItem, ListItemMeta } from '../list/ListItem'
import { Title } from '../text/Title'

export type CheckableProps = {
  icon?: Design.IvyIcon
  avatar?: ReactNode
  title: ReactNode
  description?: ReactNode
  radio?: ReactNode
  size?: 'lg' | 'sm'
  checked?: boolean
  onClick?: MouseEventHandler
  color?: string
  iconSize?: number
  translate?: 'yes' | 'no'
  onCheck?: (checked: boolean) => void
  iconColor?: string
}

const Checkable: FC<CheckableProps> = ({
  icon,
  avatar,
  title,
  translate = 'no',
  onCheck = () => {},
  description,
  checked: defaultChecked = false,
  radio,
}) => {
  const { token } = useColor()
  const [checked, toggle] = useToggle(defaultChecked)

  return (
    <Row
      style={{
        background: checked ? Color(token.colorPrimary).fade(0.75).hexa() : undefined,
        padding: '16px 32px',
        cursor: 'pointer',
        width: '100%',
        border: `1px solid ${token.colorBorder}`,
        borderRadius: token.borderRadius,
      }}
      wrap={false}
      gutter={16}
      align={'middle'}
      onClick={toggle}
      justify={'space-between'}
    >
      <Col>
        <Row gutter={32} wrap={false}>
          {icon ? (
            <Col>
              <Avatar shape='square' size={48} icon={icon && <IvyIcon size={24} type={icon} />} />
            </Col>
          ) : (
            avatar
          )}
          <Col>
            <Row align={'middle'} style={{ height: '100%' }}>
              <Col span={24}>
                {typeof title === 'string' ? (
                  <Title translate={translate} level={4}>
                    {title}
                  </Title>
                ) : (
                  title
                )}
              </Col>
              {description && (
                <Col span={24}>
                  {typeof description === 'string' ? <Text translate={translate}>{description}</Text> : description}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col>{radio ?? <Radio checked={checked} onClick={toggle} onChange={(e) => onCheck(e.target.checked)} />}</Col>
    </Row>
  )
}

interface ICheckableGroupItem<T extends { [K in keyof T]: string[] }> {
  title: ReactNode
  description: ReactNode
  icon?: Design.IvyIcon
  value: { [K in keyof T]: string }
  hidden?: boolean
}

interface ICheckableGroup<T extends { [K in keyof T]: string[] }> {
  defaultValue?: { [K in keyof T]: string }
  items: ICheckableGroupItem<T>[]
  onChange: (value?: { [K in keyof T]: string }) => void
}

function CheckableGroup<T extends { [K in keyof T]: string[] }>({ defaultValue, items, onChange }: ICheckableGroup<T>) {
  const [currentValue, setCurrentValue] = useState<{ [K in keyof T]: string }>()

  useEffect(() => {
    onChange(currentValue)
  }, [currentValue, onChange])

  return (
    <Radio.Group
      defaultValue={defaultValue}
      value={currentValue}
      optionType={'default'}
      onChange={(e) => setCurrentValue(e.target.value)}
    >
      {items
        .filter((i) => !i.hidden)
        .map((item, index) => (
          <ListItem key={index} onClick={() => setCurrentValue(item.value)}>
            <ListItemMeta
              key={index}
              onClick={() => onChange(item.value)}
              avatar={{ icon: item.icon }}
              title={item.title}
              description={item.description}
              extra={<Radio.Button value={item.value} />}
            />
          </ListItem>
        ))}
    </Radio.Group>
  )
}

export { Checkable as default, CheckableGroup }
